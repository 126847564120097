.mainPaper {

    /* width: 100vw; */
    /* height: 100vh; */
    background: linear-gradient(to right,
            #1e1e1e,
            black,
            #1e1e1e,
            black,
            #1e1e1e,

            #1e1e1e);
}

.textGradient {
    /* border: 1px solid red; */

    background: -webkit-linear-gradient(white, #33BBCF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.allauditCardBackground {
    /* width: 95%;
    margin-top: 5em !important;
    margin-bottom: 5em !important;
    margin: auto;
    border-radius: 25px; */
    /* border: 1px solid red; */
    background: linear-gradient(to right bottom,
            #1e1e1e,
            black,
            #2E2A34,
            black,
            #1e1e1e);
    padding: 1em 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
}